import React, { FC, useEffect, useMemo, useState } from 'react';
import { Box, CircularProgress, Grid, Link, Stack } from '@mui/material';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

import * as S from './styles';
import { Button, Typography } from '../../components';
import { Stepper } from '../../components/Common/Stepper';
import { getRegisterForms, register } from '../../redux/actions';
import { RootState } from '../../redux/reducers';
import {
  buildFormInitialData,
  buildFormSchema,
  getFormikTouchedValue,
} from '../../utils';
import { IForm } from '../../interfaces';
import { FormBuilder } from '../../components/FormBuilder';

export const RegisterPage: FC = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();

  const { registerForms } = useSelector(
    ({ authReducer: { registerForms } }: RootState) => ({
      registerForms,
    })
  );

  const [step, setStep] = useState(0);
  const [preStep, setPreStep] = useState(-1);
  const [registerSucceed, setRegisterSucceed] = useState(false);
  const [formikTouched, setFormikTouched] = useState<any>({});
  const [formLoading, setFormLoading] = useState(false);
  const [registerLoading, setRegisterLoading] = useState(false);
  const [initData, setInitData] = useState({});

  useEffect(() => {
    (async () => {
      setFormLoading(true);
      await dispatch(getRegisterForms());
      setInitData(buildFormInitialData(registerForms));
      setFormLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      setFormikTouched(getFormikTouchedValue(initData));
    })();
  }, [initData]);

  const currentStepContent: IForm = useMemo(
    () =>
      registerForms &&
      (Object.values(registerForms)[preStep > -1 ? preStep : step] as IForm),
    [step, preStep, registerForms]
  );
  const currentStepKey: string = useMemo(
    () =>
      registerForms &&
      Object.keys(registerForms)[preStep > -1 ? preStep : step],
    [step, preStep, registerForms]
  );

  const formik = useFormik<any>({
    enableReinitialize: true,
    initialValues: buildFormInitialData(registerForms),
    validationSchema: buildFormSchema(registerForms),
    onSubmit: async (values) => {
      try {
        setRegisterLoading(true);
        const res = (await dispatch(register(values))) as any;
        setRegisterLoading(false);
        if (res?.value?.success) {
          setRegisterSucceed(true);
          enqueueSnackbar(res?.value?.message, { variant: 'success' });
        } else {
          enqueueSnackbar(res?.value?.message, { variant: 'error' });
        }
      } catch (e: any) {
        setRegisterLoading(false);
        enqueueSnackbar(e?.response?.data?.message || 'Failed to register', {
          variant: 'error',
        });
      }
      setRegisterLoading(false);
    },
  });

  const handleNextClick = async () => {
    if (step === 1) {
      formik?.handleSubmit();
      return;
    } else {
      if (formikTouched)
        await formik?.setTouched(
          { [currentStepKey]: formikTouched[currentStepKey] },
          true
        );
      setPreStep(step);
      setStep(step + 1);
    }
  };

  useEffect(() => {
    if (step > preStep && preStep > -1) {
      const previousKey = Object.keys(registerForms)[preStep];
      if (formik.errors[previousKey]) {
        setStep(preStep);
      }
      setPreStep(-1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preStep, formik.errors, step]);

  const handleBackClick = () => {
    if (step === 1) {
      setStep(step - 1);
    }
  };

  return (
    <S.Container>
      <Box display="flex" flexDirection="column" alignItems="center">
        <S.Logo src="/assets/images/logo.svg" alt="logo" />
        {registerSucceed ? (
          <>
            <S.Alert severity="info">
              {t('register.register_success_info')}
            </S.Alert>
            <Link href="/auth/signup">
              <Typography variant="h4">{t('login.sign_in')}</Typography>
            </Link>
          </>
        ) : (
          <>
            <form onSubmit={formik.handleSubmit}>
              <S.Card>
                <S.Title mb={16}>
                  <Typography variant="h1" sx={{ textTransform: 'uppercase' }}>
                    {t('login.sign_up')}
                  </Typography>
                </S.Title>

                <Box width="100%" mb={12}>
                  <Stepper
                    step={preStep > -1 ? preStep : step}
                    onChangeStep={setStep}
                    length={2}
                  />
                </Box>

                {formLoading ? (
                  <CircularProgress sx={{ my: 16 }} />
                ) : (
                  <Grid container columns={1} spacing={16}>
                    <FormBuilder
                      type={currentStepContent?.type}
                      attributes={currentStepContent?.attributes}
                      label={currentStepContent?.label}
                      fields={currentStepContent?.fields}
                      formik={formik}
                      path={currentStepKey}
                    />
                  </Grid>
                )}

                <Stack
                  width="100%"
                  mt={16}
                  gap={8}
                  sx={{
                    flexDirection: { sm: 'row', xs: 'column' },
                    justifyContent: { sm: 'flex-end', xs: 'center' },
                  }}
                >
                  <Button
                    size="large"
                    disabled={formLoading || step === 0}
                    onClick={handleBackClick}
                  >
                    {t('register.back')}
                  </Button>
                  <Button
                    size="large"
                    disabled={formLoading || step === 1}
                    onClick={handleNextClick}
                  >
                    {t('register.next')}
                  </Button>
                  {step === 1 && (
                    <Button
                      size="large"
                      disabled={formLoading}
                      loading={registerLoading}
                      color="primary"
                      type="submit"
                    >
                      {t('register.register')}
                    </Button>
                  )}
                </Stack>
              </S.Card>
            </form>
            <Box
              sx={{ flexDirection: { sm: 'row', xs: 'column' } }}
              display="flex"
              mt={16}
            >
              <Typography variant="body1" mr={8}>
                {t('register.already_have_account')}
              </Typography>
              <Link href="/auth/signup">
                <Typography variant="h4">{t('login.sign_in')}</Typography>
              </Link>
            </Box>
          </>
        )}
      </Box>
    </S.Container>
  );
};
