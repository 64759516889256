// Dependencies
import React, { FC, useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { Box, Grid, Stack, useTheme } from '@mui/material';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';

// Components
import { Button, Icon } from '../../Common';
import { Stepper } from '../../Common/Stepper';
import { FormBuilder } from '../../FormBuilder';
import { SaveDraftModal } from '../../Modals/SaveDraftModal';

// Actions
import {
  createNewClient,
  refetchProjects,
  createDraft,
  getDrafts,
} from '../../../redux/actions';

// Constants
import { ROUTES } from '../../../constants';

// interfaces
import { RootState } from '../../../redux/reducers';
import { IForm } from '../../../interfaces';

// Utils
import {
  buildFormInitialDataWithValuesFromStructure,
  buildFormSchema,
  checkAllRequiredFilesAttached,
  getFormikTouchedValue,
  rebuildClientFormValue,
} from '../../../utils';

// Styles
import * as S from './styles';
import { CloseConfirmModal, CloseConfirmReason } from '../CloseConfirmModal';

const FORM_CACHE_KEY = 'client-form-cache';
const FORM_ALLOW_ADD_STEP = 1;

interface ICreateNewClientModal {
  open: boolean;
  onClose: () => void;
  createClientFormsData?: any;
}

export const CreateNewClientModal: FC<ICreateNewClientModal> = ({
  open,
  createClientFormsData = {},
  onClose,
}) => {
  // Get translation from hook
  const { t } = useTranslation();

  // Get navigate from hook
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { enqueueSnackbar } = useSnackbar();

  const theme = useTheme();

  const dispatch = useDispatch();
  const createClientForms = useSelector(
    ({ clientReducer: { createClientForms } }: RootState) => createClientForms
  );

  const [closeModal, setCloseModal] = useState(false);
  const [clearFormConfirmModal, setClearFormConfirmModal] = useState(false);
  const [createClientLoading, setCreateClientLoading] = useState(false);
  const [submittedConfirmModal, setSubmittedConfirmModal] = useState(false);
  const [saveDataModal, setSaveDataModal] = useState<any>(false);
  const [missingFilesModal, setMissingFilesModal] = useState(false);
  const [openConfirmDraftModal, setOpenConfirmDraftModal] = useState(false);
  const [validatedSteps, setValidatedSteps] = useState<number[]>([]);
  const [steps, setSteps] = useState<string[]>([]);
  const [step, setStep] = useState(0);
  const [preStep, setPreStep] = useState(-1);
  const [formInitData, setFormInitData] = useState<any>({});
  const [missingFiles, setMissingFiles] = useState<any>([]);

  useEffect(() => {
    const initData = buildFormInitialDataWithValuesFromStructure(
      createClientFormsData,
      createClientForms
    );
    setFormInitData(initData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (steps.length === 0) {
      let initialSteps: string[] = [];
      if (createClientForms)
        Object.entries(createClientForms)?.forEach((field: any) => {
          if (field[1].type === 'array' && field[1].allowAdd) {
            initialSteps.push(`${field[0]}[0]`);
          } else {
            initialSteps.push(field[0]);
          }
        });

      setSteps(initialSteps);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createClientForms]);

  const formik = useFormik<any>({
    initialValues: buildFormInitialDataWithValuesFromStructure(
      createClientFormsData,
      createClientForms
    ),
    validationSchema: buildFormSchema(createClientForms),
    onSubmit: async (values) => {
      const missingFileFields = checkAllRequiredFilesAttached(
        values,
        createClientForms
      );
      setMissingFiles(missingFileFields);

      const formValue = rebuildClientFormValue(values, createClientForms);

      setCreateClientLoading(true);
      try {
        const res: any = await dispatch(createNewClient(formValue));
        setCreateClientLoading(false);
        if (res?.value?.success) {
          localStorage.setItem(FORM_CACHE_KEY, '');
          enqueueSnackbar(res?.value?.message, { variant: 'success' });

          if (!missingFileFields.length) {
            setSubmittedConfirmModal(true);
          } else {
            setMissingFilesModal(true);
          }
        } else {
          enqueueSnackbar(res?.value?.message, { variant: 'error' });
        }
      } catch (e: any) {
        setCreateClientLoading(false);
        enqueueSnackbar(
          e.response?.data?.message || 'Failed to create client',
          { variant: 'error' }
        );
      }
    },
  });

  const currentStepKey: string = useMemo(
    () => steps[preStep > -1 ? preStep : step],
    [preStep, step, steps]
  );

  const currentStepContent: IForm = useMemo(() => {
    if (createClientForms && currentStepKey) {
      return createClientForms[currentStepKey.replace(/\[(\d+)\]/, '')];
    }
    return {};
  }, [createClientForms, currentStepKey]);

  const isVisibleRemoveStepButton = useMemo(() => {
    if (!currentStepKey) return false;

    return formik?.values[currentStepKey.replace(/\[(\d+)\]/, '')]?.length > 1;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [steps, step, preStep, currentStepKey]);

  const canSubmit = useMemo(() => !Object.keys(formik.errors).length, [formik]);

  const handleNextStepClicked = async () => {
    if (step === steps.length - 1) {
      if (!canSubmit) {
        setSaveDataModal(true);
      }

      formik.handleSubmit();
      return;
    } else {
      if (currentStepKey !== 'propertyFiles') {
        const key = currentStepKey?.replace(/\[(\d+)\]/, '');
        let touchedValue: any;
        if (
          currentStepContent.type === 'array' &&
          currentStepContent.allowAdd
        ) {
          const nthTouchedValue = getFormikTouchedValue(formInitData[key][0]);
          touchedValue = formik.values[key].map((val, index) =>
            index === step - FORM_ALLOW_ADD_STEP ? nthTouchedValue : false
          );
        } else {
          touchedValue = getFormikTouchedValue(formInitData[key]);
        }

        await formik.setTouched({ [key]: touchedValue }, true);
        setPreStep(step);
      }
      setStep(step + 1);
    }
  };

  useEffect(() => {
    if (step > preStep && preStep > -1) {
      const previousKey = steps[preStep].replace(/\[(\d+)\]/, '');
      if (formik.errors[previousKey]) {
        if (
          currentStepContent.type === 'array' &&
          currentStepContent.allowAdd
        ) {
          // @ts-ignore
          if (formik?.errors[previousKey][preStep - FORM_ALLOW_ADD_STEP]) {
            setStep(preStep);
          }
        } else {
          // @ts-ignore
          setStep(preStep);
        }
      } else {
        let newSteps = validatedSteps;
        if (!validatedSteps.includes(preStep)) {
          newSteps = [...newSteps, preStep];
        }

        if (!validatedSteps.includes(preStep + 1)) {
          newSteps = [...newSteps, preStep + 1];
        }
        setValidatedSteps(newSteps);
      }
      setPreStep(-1);
    }

    if (step === steps.length - 1) {
      const missingFileFields = checkAllRequiredFilesAttached(
        formik.values,
        createClientForms
      );
      setMissingFiles(missingFileFields);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preStep, formik.errors, step]);

  const handleBackStep = () => {
    setStep(step - 1);
  };

  // TODO: Draft killed
  const handleClose = () => {
    setOpenConfirmDraftModal(true);
    // onClose();
  };

  const handleDeclining = () => {
    setOpenConfirmDraftModal(false);
    onClose();
  };

  const handleCloseConfirmDraftModal = () => {
    setOpenConfirmDraftModal(false);
  };

  const handleSaveDraft = async () => {
    if (
      formik.values.personalData.first_name &&
      formik.values.personalData.last_name
    ) {
      dispatch(createDraft(formik.values));
      dispatch(getDrafts());
      handleCloseConfirmDraftModal();
      onClose();
    } else {
    }
  };

  const handleCancelConfirmModal = (
    event?: any,
    reason?: CloseConfirmReason
  ) => {
    setCloseModal(false);
    setSaveDataModal(false);
    if (reason === 'cancelClick') {
      setClearFormConfirmModal(true);
    }
  };

  const handleCloseSubmittedConfirmModal = () => {
    onClose();
  };

  const handleConfirmSubmittedConfirmModal = () => {
    if (pathname === ROUTES.CLIENTS.INDEX + `/new`) {
      dispatch(refetchProjects());
    } else {
      navigate(ROUTES.CLIENTS.INDEX + `/new`);
    }
    onClose();
  };

  const gridRef = React.createRef<HTMLDivElement>();

  const handleChangeStep = async (stepIndex: number) => {
    setStep(stepIndex);
    if (gridRef.current) gridRef.current.scrollIntoView();
  };

  const handleAddStep = () => {
    const count = formik.values[currentStepKey.replace(/\[(\d+)\]/, '')].length;

    const newSteps: string[] = [];
    steps.forEach((item) => {
      newSteps.push(item);
      if (item === currentStepKey) {
        newSteps.push(item.replace(/\[(\d+)\]/, '') + `[${count}]`);
      }
    });

    let formikValues: any = {};

    Object.keys(formik.values).forEach((key) => {
      if (Array.isArray(formik?.values[key])) {
        formikValues[key] = [...formik.values[key], formInitData[key][0]];
      } else {
        formikValues[key] = formik.values[key];
      }
    });

    formik.setValues(formikValues);

    setSteps(newSteps);
    setStep(step + 1);
  };
  const handleRemoveStep = () => {
    const mainKey = currentStepKey.replace(/\[(\d+)\]/, '');
    const count = formik.values[mainKey].length;

    if (count > 1) {
      let values: any = formik.values[mainKey];

      formik.setValues({
        ...formik.values,
        [mainKey]: values.filter(
          (_, index) => index !== step - FORM_ALLOW_ADD_STEP
        ),
      });

      setSteps(steps.filter((item) => item !== `${mainKey}[${count - 1}]`));
      setStep(step - 1);
    }
  };
  return (
    <S.CrateNewClientDialog
      open={open}
      onClose={handleClose}
      title={t('new_client.new_client')}
      actions={
        <>
          <Button
            sx={{ flexGrow: { xs: 1, sm: 0 } }}
            size="large"
            onClick={handleBackStep}
            disabled={step === 0}
          >
            {t('new_client.back')}
          </Button>
          {(step === steps.length - 1 || step < steps.length - 1) && (
            <Button
              sx={{ ml: '16px !important', flexGrow: { xs: 1, sm: 0 } }}
              color="primary"
              size="large"
              onClick={handleNextStepClicked}
              loading={createClientLoading}
              // disabled={step === steps.length - 1 && !canSubmit}
            >
              {step === steps.length - 1
                ? !canSubmit || missingFiles.length > 0
                  ? t('new_client.save')
                  : t('new_client.submit')
                : t('new_client.next')}
            </Button>
          )}
        </>
      }
      headerChild={
        <Stepper
          step={preStep > -1 ? preStep : step}
          onChangeStep={handleChangeStep}
          length={steps.length}
        />
      }
    >
      <Grid
        container
        ref={gridRef}
        columns={2}
        columnSpacing={42}
        rowSpacing={28}
      >
        <FormBuilder
          type={currentStepContent.type}
          attributes={currentStepContent.attributes}
          label={currentStepContent.label}
          fields={currentStepContent.fields}
          required={currentStepContent.required}
          formik={formik}
          path={currentStepKey}
        />
      </Grid>
      {currentStepContent?.allowAdd && (
        <Stack direction="row" spacing={20} justifyContent="center" mt={16}>
          <Button
            startIcon={<Icon name="plus-lg" />}
            sx={(theme) => ({ color: theme.palette['green'] })}
            onClick={handleAddStep}
          >
            {currentStepContent?.arrayHandlerButtonName} hinzufügen
          </Button>
          {isVisibleRemoveStepButton && (
            <Button
              startIcon={<Icon name="x-lg" color={theme.palette['red']} />}
              sx={(theme) => ({ color: theme.palette['red'] })}
              onClick={handleRemoveStep}
            >
              {currentStepContent?.arrayHandlerButtonName} entfernen
            </Button>
          )}
        </Stack>
      )}

      {/* <CloseConfirmModal
        open={closeModal}
        title={t('close_confirm.want_to_store_entered_form')}
        text={t('close_confirm.want_to_store_entered_form_text')}
        onClose={handleCloseConfirmModal}
        onCancel={handleCancelConfirmModal}
      /> */}

      {/* <CloseConfirmModal
        open={clearFormConfirmModal}
        text={t('close_confirm.want_to_keep_saved_data_text')}
        title={t('close_confirm.want_to_keep_saved_data')}
        onClose={handleCloseClearFormConfirmModal}
        onCancel={handleCancelClearFormConfirmModal}
      /> */}

      <CloseConfirmModal
        open={submittedConfirmModal}
        title={t('close_confirm.customer_submitted')}
        text={t('close_confirm.submitted_validated_customer_data')}
        hideCancel
        confirmLabel={t('confirm.ok')}
        onClose={handleConfirmSubmittedConfirmModal}
        onCancel={handleCloseSubmittedConfirmModal}
      />

      <CloseConfirmModal
        open={missingFilesModal}
        title={t('close_confirm.customer_saved')}
        hideCancel
        confirmLabel={t('confirm.ok')}
        text={
          <>
            <Box mb={12}>{t('close_confirm.submitted_missing_files')}</Box>
            {missingFiles.map((fileField) => (
              <Box>{fileField.label}</Box>
            ))}
          </>
        }
        onClose={handleConfirmSubmittedConfirmModal}
        onCancel={handleCloseSubmittedConfirmModal}
      />

      <SaveDraftModal
        open={openConfirmDraftModal}
        onClose={handleCloseConfirmDraftModal}
        onOk={handleSaveDraft}
        onDeclining={handleDeclining}
        firstOrLastNameError={Boolean(
          formik?.values?.personalData?.first_name === '' ||
            formik?.values?.personalData?.last_name === ''
        )}
      />

      <CloseConfirmModal
        open={saveDataModal}
        title={t('close_confirm.missing_fields')}
        hideCancel
        confirmLabel={t('close_confirm.continue')}
        text={
          <>
            <Box mb={10}>{t('close_confirm.missing_fields_text')}</Box>
            {Object.keys(formik.errors).map((key, index) => {
              let page = steps.reduce(
                (page, formKey, pageIndex) =>
                  formKey === key ? pageIndex + 1 : page,
                0
              );
              const formFieldValue: any = createClientForms[key];
              if (Array.isArray(formik.errors[key])) {
                // @ts-ignore
                return formik.errors[key]?.map(
                  (errorObject: any, index: number) => {
                    if (!errorObject) {
                      return null;
                    }
                    page = steps.reduce(
                      (page, formKey, pageIndex) =>
                        formKey === `${key}[${index}]` ? pageIndex + 1 : page,
                      0
                    );
                    return Object.keys(errorObject).map((subKey, subIndex) => {
                      let subFormLabel = formFieldValue.fields[subKey]?.label;
                      return (
                        <Box key={subIndex}>
                          {subFormLabel}: Page {page}
                        </Box>
                      );
                    });
                  }
                );
              } else {
                const subKeys = Object.keys(formik.errors[key] || {});
                return subKeys.map((subKey, subIndex) => {
                  let subFormLabel = formFieldValue.fields[subKey]?.label;

                  if (!subFormLabel) {
                    // @ts-ignore
                    subFormLabel =
                      Object.values(
                        formFieldValue?.fields[subKey]?.fields || {}
                        // @ts-ignore
                      )?.find((item: any) => item.type === 'headline')?.label ||
                      subKey[0].toUpperCase() + subKey.slice(1);
                  }

                  return (
                    <Box key={subIndex}>
                      {subFormLabel}: Page {page}
                    </Box>
                  );
                });
              }
            })}
          </>
        }
        onClose={handleCancelConfirmModal}
        onCancel={handleCancelConfirmModal}
      />
    </S.CrateNewClientDialog>
  );
};
